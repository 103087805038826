import React from 'react';

const PaymentHistory: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="160" height="160" fill="none">
            <path
                fill="#F2F9FC"
                d="M1.6 80c0 60.352 65.333 98.073 117.6 67.896A78.4 78.4 0 0 0 158.4 80c0-60.352-65.333-98.073-117.6-67.896A78.4 78.4 0 0 0 1.6 80"
                style={{ mixBlendMode: 'multiply' }}
            />
            <path
                fill="#5CC4ED"
                d="M47.478 136.46c-.016 2.602 22.992 4.231 41.415 2.932 8.563-.604 13.837-1.723 13.829-2.932.016-2.603-22.992-4.232-41.415-2.932-8.563.604-13.837 1.722-13.83 2.932M126.111 119.943H24.089a5.19 5.19 0 0 1-5.186-5.188V43.86h112.396v70.895a5.19 5.19 0 0 1-5.188 5.188"
            />
            <path
                fill="#fff"
                d="M126.111 28.297H24.089a5.19 5.19 0 0 0-5.186 5.188V43.86h112.396V33.485a5.19 5.19 0 0 0-5.188-5.188"
            />
            <path
                stroke="#222A3F"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M126.111 28.297H24.089a5.19 5.19 0 0 0-5.186 5.188V43.86h112.396V33.485a5.19 5.19 0 0 0-5.188-5.188M126.111 119.943H24.089a5.19 5.19 0 0 1-5.186-5.188V43.86h112.396v70.895a5.19 5.19 0 0 1-5.188 5.188"
            />
            <path
                fill="#5CC4ED"
                d="M91.19 10.138h53.837a7.27 7.27 0 0 1 7.27 7.27v29.325a7.27 7.27 0 0 1-7.27 7.27h-53.84a7.27 7.27 0 0 1-7.267-7.268V17.407a7.27 7.27 0 0 1 7.27-7.27"
            />
            <path
                stroke="#222A3F"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M92.952 43.681h12.9"
            />
            <path
                fill="#fff"
                stroke="#222A3F"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M92.952 25.62h12.9v9.03h-12.9z"
            />
            <path
                stroke="#222A3F"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M91.19 10.138h53.837a7.27 7.27 0 0 1 7.27 7.27v29.325a7.27 7.27 0 0 1-7.27 7.27h-53.84a7.27 7.27 0 0 1-7.267-7.268V17.407a7.27 7.27 0 0 1 7.27-7.27"
            />
            <path
                fill="#fff"
                d="M121.334 39.81c-.008 5.959 6.437 9.692 11.601 6.72a7.74 7.74 0 0 0 3.88-6.72c.008-5.958-6.437-9.691-11.601-6.72a7.74 7.74 0 0 0-3.88 6.72"
            />
            <path
                fill="#fff"
                d="M130.364 39.81c-.008 5.959 6.436 9.692 11.601 6.72a7.74 7.74 0 0 0 3.879-6.72c.009-5.958-6.436-9.691-11.6-6.72a7.74 7.74 0 0 0-3.88 6.72"
            />
            <path
                fill="#5CC4ED"
                d="M130.372 39.82a7.73 7.73 0 0 0 3.218 6.272c4.298-3.087 4.298-9.482 0-12.57a7.73 7.73 0 0 0-3.218 6.298"
            />
            <path
                stroke="#222A3F"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M121.334 39.81c-.008 5.959 6.437 9.692 11.601 6.72a7.74 7.74 0 0 0 3.88-6.72c.008-5.958-6.437-9.691-11.601-6.72a7.74 7.74 0 0 0-3.88 6.72"
            />
            <path
                stroke="#222A3F"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M130.364 39.81c-.008 5.959 6.436 9.692 11.601 6.72a7.74 7.74 0 0 0 3.879-6.72c.009-5.958-6.436-9.691-11.6-6.72a7.74 7.74 0 0 0-3.88 6.72"
            />
            <path
                fill="#5CC4ED"
                stroke="#222A3F"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M24.091 36.078c-.005 1.997 2.153 3.25 3.884 2.256a2.59 2.59 0 0 0 1.302-2.256c.005-1.996-2.153-3.249-3.884-2.255a2.59 2.59 0 0 0-1.302 2.255M34.207 36.078c-.005 1.997 2.152 3.25 3.884 2.256a2.59 2.59 0 0 0 1.302-2.256c.005-1.996-2.153-3.249-3.884-2.255a2.6 2.6 0 0 0-1.302 2.255M44.322 36.078c-.005 1.997 2.153 3.25 3.884 2.256a2.6 2.6 0 0 0 1.302-2.256c.005-1.996-2.153-3.249-3.884-2.255a2.6 2.6 0 0 0-1.302 2.255"
            />
        </svg>
    );
};

export default PaymentHistory;
